exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-big-data-analysis-startup-js": () => import("./../../../src/pages/big-data-analysis-startup.js" /* webpackChunkName: "component---src-pages-big-data-analysis-startup-js" */),
  "component---src-pages-blog-blog-details-js": () => import("./../../../src/pages/blog/blog-details.js" /* webpackChunkName: "component---src-pages-blog-blog-details-js" */),
  "component---src-pages-blog-categories-js": () => import("./../../../src/pages/blog/categories.js" /* webpackChunkName: "component---src-pages-blog-categories-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-tags-js": () => import("./../../../src/pages/blog/tags.js" /* webpackChunkName: "component---src-pages-blog-tags-js" */),
  "component---src-pages-case-studies-case-1-studies-details-data-manager-js": () => import("./../../../src/pages/case-studies/case1-studies-details-data-manager.js" /* webpackChunkName: "component---src-pages-case-studies-case-1-studies-details-data-manager-js" */),
  "component---src-pages-case-studies-case-2-studies-details-data-manager-js": () => import("./../../../src/pages/case-studies/case2-studies-details-data-manager.js" /* webpackChunkName: "component---src-pages-case-studies-case-2-studies-details-data-manager-js" */),
  "component---src-pages-case-studies-case-studies-details-js": () => import("./../../../src/pages/case-studies/case-studies-details.js" /* webpackChunkName: "component---src-pages-case-studies-case-studies-details-js" */),
  "component---src-pages-case-studies-index-js": () => import("./../../../src/pages/case-studies/index.js" /* webpackChunkName: "component---src-pages-case-studies-index-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-courses-course-details-js": () => import("./../../../src/pages/courses/course-details.js" /* webpackChunkName: "component---src-pages-courses-course-details-js" */),
  "component---src-pages-courses-index-js": () => import("./../../../src/pages/courses/index.js" /* webpackChunkName: "component---src-pages-courses-index-js" */),
  "component---src-pages-data-analytics-ai-startup-js": () => import("./../../../src/pages/data-analytics-ai-startup.js" /* webpackChunkName: "component---src-pages-data-analytics-ai-startup-js" */),
  "component---src-pages-data-analytics-ml-consulting-js": () => import("./../../../src/pages/data-analytics-ml-consulting.js" /* webpackChunkName: "component---src-pages-data-analytics-ml-consulting-js" */),
  "component---src-pages-data-science-ml-company-js": () => import("./../../../src/pages/data-science-ml-company.js" /* webpackChunkName: "component---src-pages-data-science-ml-company-js" */),
  "component---src-pages-data-science-online-courses-js": () => import("./../../../src/pages/data-science-online-courses.js" /* webpackChunkName: "component---src-pages-data-science-online-courses-js" */),
  "component---src-pages-digital-marketing-agency-js": () => import("./../../../src/pages/digital-marketing-agency.js" /* webpackChunkName: "component---src-pages-digital-marketing-agency-js" */),
  "component---src-pages-events-event-details-js": () => import("./../../../src/pages/events/event-details.js" /* webpackChunkName: "component---src-pages-events-event-details-js" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intelligent-operations-center-js": () => import("./../../../src/pages/intelligent-operations-center.js" /* webpackChunkName: "component---src-pages-intelligent-operations-center-js" */),
  "component---src-pages-iot-js": () => import("./../../../src/pages/iot.js" /* webpackChunkName: "component---src-pages-iot-js" */),
  "component---src-pages-machine-learning-ai-solutions-js": () => import("./../../../src/pages/machine-learning-ai-solutions.js" /* webpackChunkName: "component---src-pages-machine-learning-ai-solutions-js" */),
  "component---src-pages-membership-levels-js": () => import("./../../../src/pages/membership-levels.js" /* webpackChunkName: "component---src-pages-membership-levels-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-profile-authentication-js": () => import("./../../../src/pages/profile-authentication.js" /* webpackChunkName: "component---src-pages-profile-authentication-js" */),
  "component---src-pages-seo-agency-js": () => import("./../../../src/pages/seo-agency.js" /* webpackChunkName: "component---src-pages-seo-agency-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-service-details-js": () => import("./../../../src/pages/services/service-details.js" /* webpackChunkName: "component---src-pages-services-service-details-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-categorie-template-js": () => import("./../../../src/templates/categorie-template.js" /* webpackChunkName: "component---src-templates-categorie-template-js" */),
  "component---src-templates-project-template-js": () => import("./../../../src/templates/project-template.js" /* webpackChunkName: "component---src-templates-project-template-js" */),
  "component---src-templates-service-template-js": () => import("./../../../src/templates/service-template.js" /* webpackChunkName: "component---src-templates-service-template-js" */),
  "component---src-templates-tag-template-js": () => import("./../../../src/templates/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

